import React, { ComponentPropsWithoutRef, memo } from 'react';
import { createStyles, Title } from '@mantine/core';

export const HeadingOne = memo(
  ({
    className,
    classNames,
    ...props
  }: ComponentPropsWithoutRef<typeof Title>) => {
    const { classes, cx } = useStyles();

    return (
      <Title
        className={cx(classes.title, className, classNames)}
        {...props}
        order={1}
      />
    );
  },
);

const useStyles = createStyles(theme => ({
  title: {
    fontSize: 72,
    fontWeight: 800,
    lineHeight: "80px",
    fontFamily: 'Poppins, Helvetica Neue, Helvetica, Arial, sans-serif',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: 48,
      lineHeight: "54px",
    },
  },
}));
