/**
 *
 * Wrapper
 *
 */
import React, { ComponentProps } from "react";
import { Center, createStyles, Group, Stack } from "@mantine/core";
import Hero from '../../assets/illustration.svg';

export function Wrapper({ children }: ComponentProps<any>) {
  const { classes } = useStyles();

  return (
    <Group
      grow
      noWrap
      align="flex-start"
      position="apart"
      spacing={130}
      className={classes.wrapper}
    >
      <Stack justify="flex-end" className={classes.hideOnMobile}>
        <Center className={classes.heroContainer}>
          <Hero className={classes.hero} />
        </Center>
        {/* TODO: Add credits */}
        {/* <Box p="md">
          <Anchor
            href="https://it.freepik.com/vettori/tecnologia"
            title="Hero image credits"
            size="xs"
            target="_blank"
            className={classes.credits}
          >
            Immagine creata da storyset - it.freepik.com
          </Anchor>
        </Box> */}
      </Stack>
      <Stack spacing={0} className={classes.body}>
        {children}
      </Stack>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: "column",
      alignItems: "center",
      flexWrap: "wrap",
      gap: 24,

      ['& > div']: {
        flexGrow: 0,
        maxWidth: "none",
      }
    },
  },

  hideOnMobile: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      display: "none"
    },
  },

  heroContainer: { flex: 1 },

  hero: {
    width: "100%",
    maxWidth: 550,
    height: "auto",
    marginLeft: 70,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginRight: 70,
    },
  },

  credits: {
    color: theme.colors.alfredo[2],
  },

  body: {
    paddingTop: 40,
    paddingBottom: 40,
    marginRight: 100,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: "column",
      marginTop: 24,
      marginBottom: 24,
      marginRight: 24,
      marginLeft: 24,
      padding: 24,
      paddingBottom: 36,
      overflow: "auto",
    },
  },
}));
