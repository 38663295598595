import React from 'react';

export function GreenModLogo({
  width = 98,
  height = 50,
  ...others
}: React.ComponentPropsWithoutRef<'img'>) {
  return (
    <img
      alt="GreenMod"
      height={height}
      width={width}
      {...others}
      src='/img/logo_white.png'
    />
  );
}
