import {
  createStyles,
  Group,
  MantineProvider,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Button } from "../components/base/Button";
import { HeadingOne } from "../components/base/typographies/HeadingOne";
import { ErrorBox } from "../components/ErrorBox";
import { GreenModLogo } from "../components/GreenModLogo";
import { SuccessBox } from "../components/SuccessBox";
import { Wrapper } from "../components/Wrapper";
import { GlobalStyle } from "../styles/global-styles";
import { alfredoMantineTheme } from "../styles/mantine";
import { HubInfo } from "../types/hub_info";
import { rgba } from "../utils/rgba";
import { CSSTransition } from "react-transition-group";
import "../styles/transitions.css";

const fadeInDuration = 250;
const shakeDuration = 400;

const IndexPage = () => {
  const { classes } = useStyles();

  const [hubInfo, setHubInfo] = useState<HubInfo | null>(null);
  const [error, setError] = useState<number | null>(null);
  const [missingSeconds, setMissingSeconds] = useState<number>(0);
  const [shouldShakeError, setShouldShakeError] = useState<boolean>(false);

  const endPageRef = useRef(null);

  // Automatically scrolls to the end of the page
  useEffect(() => {
    if (hubInfo !== null || error !== null) {
      (endPageRef.current as any)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [hubInfo, error]);

  // Resets the shake effect
  useEffect(() => {
    if (shouldShakeError) {
      setTimeout(() => {
        setShouldShakeError(false);
      }, shakeDuration);
    }
  }, [shouldShakeError, setShouldShakeError]);

  const form = useForm({
    initialValues: {
      serial: "",
    },
    validate: {
      serial: (value: string) =>
        value.trim().length === 0
          ? "Inserisci un codice seriale valido"
          : undefined,
    },
  });

  // Controls the submit button disabled timer when too much requests are sent (error code 429)
  const disableTimer = useRef<NodeJS.Timer>();
  const startDisableTimer = useCallback(() => {
    if (disableTimer.current) {
      clearInterval(disableTimer.current);
    }

    setMissingSeconds(15);
    disableTimer.current = setInterval(
      () =>
        setMissingSeconds((current) => {
          if (current > 0) {
            return current - 1;
          } else {
            clearInterval(disableTimer.current);
            return 0;
          }
        }),
      1000
    );
  }, [disableTimer.current, missingSeconds, setMissingSeconds]);

  // Hides the error box and shows the box with the hub info
  const showHubInfo = useCallback(
    (info: HubInfo) => {
      setError(null);

      if (!info.data.is_online || error === null) {
        setHubInfo(info);
      } else {
        setTimeout(() => setHubInfo(info), fadeInDuration);
      }
    },
    [setError, error, setHubInfo]
  );

  // Shakes the error box
  const shakeError = useCallback(() => {
    if (error === null) {
      setTimeout(() => setShouldShakeError(true), fadeInDuration);
    } else {
      setShouldShakeError(true);
    }
  }, [error, setShouldShakeError]);

  // Hides the info box and shows the error box
  const showError = useCallback(
    (errorCode: number) => {
      setHubInfo(null);

      if (hubInfo === null || !hubInfo.data.is_online) {
        setError(errorCode);
        shakeError();
      } else {
        setTimeout(() => {
          setError(errorCode);
          shakeError();
        }, fadeInDuration);
      }
    },
    [setHubInfo, hubInfo, setError, shakeError]
  );

  const fetchHubInfo = useCallback(
    async (serial: string) => {
      const response = await fetch(
        `https://api.alfredohome.com/alfredo/${serial}/info`
      );

      if (response.ok) {
        const hubInfo: HubInfo = await response.json();
        showHubInfo(hubInfo);

        if (!hubInfo.data.is_online) {
          shakeError();
        }
      } else {
        try {
          if (response.status === 429) {
            showNotification({
              title: "Errore",
              message:
                "Sembra che tu abbia raggiunto il limite di richieste, riprova più tardi 😕",
              color: "red",
            });
            setError(null);
            startDisableTimer();
          } else {
            showError(response.status);
          }
        } catch (e) {
          showError(404);
        }
      }
    },
    [showHubInfo, setError, showError, startDisableTimer, shakeError]
  );

  const input = useMemo(
    () => (
      <TextInput
        size="lg"
        placeholder="Inserisci il codice seriale"
        required
        autoFocus
        {...form.getInputProps("serial")}
      />
    ),
    [form]
  );

  const submit = useMemo(
    () => (
      <Button
        size="lg"
        type="submit"
        className={classes.connectButton}
        disabled={missingSeconds > 0}
      >
        {missingSeconds > 0 ? missingSeconds.toString() : "Connettiti"}
      </Button>
    ),
    [missingSeconds]
  );

  const errorBox = useMemo(
    () => (
      <CSSTransition
        unmountOnExit
        in={error !== null || (hubInfo !== null && !hubInfo.data.is_online)}
        timeout={fadeInDuration}
        classNames="box-fade-in"
      >
        <CSSTransition
          in={shouldShakeError}
          timeout={shakeDuration}
          classNames="shake"
        >
          {error !== null || (hubInfo !== null && !hubInfo.data.is_online) ? (
            <ErrorBox
              error={error}
              last_seen_at={hubInfo?.data.last_seen_at || null}
            />
          ) : (
            <div />
          )}
        </CSSTransition>
      </CSSTransition>
    ),
    [error, hubInfo, shouldShakeError]
  );

  const infoBox = useMemo(
    () => (
      <CSSTransition
        unmountOnExit
        in={hubInfo !== null && hubInfo.data.is_online}
        timeout={fadeInDuration}
        classNames="box-fade-in"
      >
        {hubInfo !== null && hubInfo.data.is_online ? (
          <SuccessBox hubInfo={hubInfo} />
        ) : (
          <div />
        )}
      </CSSTransition>
    ),
    [hubInfo]
  );

  return (
    <MantineProvider
      theme={alfredoMantineTheme}
      withNormalizeCSS
      withGlobalStyles
    >
      <GlobalStyle />
      <NotificationsProvider>
        <Helmet>
          <title>Connettiti al tuo Alfredo</title>
          <meta
            name="description"
            content="Cerca il tuo Alfredo con il codice seriale e trova il suo indirizzo IP locale"
          />
        </Helmet>
        <main className={classes.page}>
          <nav className={classes.header}>
            <GreenModLogo />
          </nav>
          <Wrapper>
            <HeadingOne className={classes.title}>
              Connettiti al tuo Alfredo
            </HeadingOne>
            <section className={classes.caption}>
              Inserisci il codice seriale che trovi indicato sul retro del tuo
              Alfredo. Questo ti permetterà di scoprire l’indirizzo IP per
              connetterti ad esso!
            </section>
            <form
              onSubmit={form.onSubmit(
                (values) => void fetchHubInfo(values.serial)
              )}
              className={classes.mobileForm}
            >
              <Stack spacing={10}>
                {input}
                <Stack spacing={40}>
                  {submit}
                  {errorBox}
                  {infoBox}
                </Stack>
              </Stack>
            </form>
            <form
              onSubmit={form.onSubmit(
                (values) => void fetchHubInfo(values.serial)
              )}
              className={classes.desktopForm}
            >
              <Group spacing={10} align="flex-start">
                <Stack spacing={40} className={classes.expanded}>
                  {input}
                  {errorBox}
                  {infoBox}
                </Stack>
                {submit}
              </Group>
            </form>
            <div ref={endPageRef} />
          </Wrapper>
        </main>
      </NotificationsProvider>
    </MantineProvider>
  );
};

const useStyles = createStyles((theme) => ({
  page: {
    color: theme.white,
  },

  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    marginBottom: 64,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginBottom: 24,
    },
  },

  title: {
    marginBottom: 22,
  },

  caption: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "40px",
    fontFamily: "Poppins, Helvetica Neue, Helvetica, Arial, sans-serif",
    marginBottom: 44,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: 20,
      lineHeight: "32px",
    },
  },

  mobileForm: {
    display: "none",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "unset",
    },
  },

  desktopForm: {
    display: "unset",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },

  expanded: { flex: 1 },

  connectButton: {
    color: theme.black,
    backgroundColor: "#DDEB88",

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      minWidth: 246,
      maxWidth: 246,
    },

    "&:hover": {
      backgroundColor: rgba("#EBDC88", 0.65),
    },
  },
}));

export default IndexPage;
