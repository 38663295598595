import React from 'react';
import { Global } from "@mantine/core";

export function GlobalStyle() {
  return (
    <Global
      styles={() => ({
        body: {
          margin: 0,
          fontFamily: "Poppins, Helvetica Neue, Helvetica, Arial, sans-serif",
          background: "linear-gradient(127.72deg, #9EFFE7 -30%, #00D19E 33%, #006B51)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      })}
    />
  );
}