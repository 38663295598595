import { createStyles, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useMemo } from 'react';
import Copy from '../../assets/copy.svg';
import ExternalLink from '../../assets/external-link.svg';

export enum IpAddressLineType {
  Wired = "wired",
  Wireless = "wireless",
  Main = "main"
}

interface Props {
  ipAddress: string;
  type: IpAddressLineType;
}

export function IpAddressLine({ ipAddress, type }: Props) {
  const { classes } = useStyles();

  const copy = () => {
    navigator.clipboard.writeText(ipAddress);
    showNotification({
      title: "Copiato!",
      message: "L'indirizzo IP è stato copiato correttamente 😉",
      color: "green"
    });
  }

  const caption = useMemo(() => {
    switch (type) {
      case IpAddressLineType.Wired:
        return "IP Ethernet";
      case IpAddressLineType.Wireless:
        return "IP WiFi";
      case IpAddressLineType.Main:
      default:
        return "Indirizzo IP";
    }
  }, [type]);

  return (
    <Group position="apart">
      <p className={classes.noMargin}><strong className={classes.strong}>{caption}</strong>: <a href={`http://${ipAddress}/`} target="_blank" className={classes.link}>{ipAddress}</a></p>
      <Group spacing={6}>
        <Copy height={22} width={22} className={classes.copy} onClick={copy} />
        <a href={`http://${ipAddress}/`} target="_blank" className={classes.openLink}>
          <ExternalLink height={22} width={22} />
        </a>
      </Group>
    </Group>
  );
}
const useStyles = createStyles(theme => ({
  noMargin: {
    margin: 0
  },

  strong: {
    fontWeight: 500
  },

  link: {
    color: theme.colors.alfredo[7],
    textDecoration: 'none',
  },

  copy: {
    cursor: 'pointer'
  },

  openLink: {
    lineHeight: 0
  },
}));
