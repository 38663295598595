import { MantineThemeOverride } from "@mantine/core";

export const alfredoMantineTheme: MantineThemeOverride = {
  fontFamily: "Poppins, Helvetica Neue, Helvetica, Arial, sans-serif",
  colors: {
    alfredo: [
      "#EFF6F4",
      "#CEE3DE",
      "#AED1C8",
      "#8DBEB2",
      "#6BAB9B",
      "#539283",
      "#417266",
      "#005641",
      "#2E5149",
      "#1C312C",
    ],
    alfredoAccent: [
      "#D1FFF4",
      "#9EFFE7",
      "#6BFFDB",
      "#38FFCF",
      "#05FFC2",
      "#00D19E",
      "#009E78",
      "#006B51",
      "#00382A",
    ],
  },
  primaryColor: "alfredo",
};
