import { HubInfo } from "../types/hub_info";

const interfacesMapping = {
  wired: ["eth0"],
  wireless: ["wlan0"],
};

export const getWiredIp = (hubInfo: HubInfo): string | null => {
  const interfaces = interfacesMapping.wired;
  for (const interfaceName of interfaces) {
    const ip = hubInfo.data.ip_addresses[interfaceName];
    if (ip) {
      return ip;
    }
  }
  return null;
};

export const getWirelessIp = (hubInfo: HubInfo): string | null => {
  const interfaces = interfacesMapping.wireless;
  for (const interfaceName of interfaces) {
    const ip = hubInfo.data.ip_addresses[interfaceName];
    if (ip) {
      return ip;
    }
  }
  return null;
};
