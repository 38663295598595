import { Box, createStyles, Group, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import { HubInfo } from '../../types/hub_info';
import { getWiredIp, getWirelessIp } from '../../utils/hub_info';
import { IpAddressLine, IpAddressLineType } from '../IpAddressLine';

interface Props {
  hubInfo: HubInfo;
}

export function SuccessBox({ hubInfo }: Props) {
  const { classes } = useStyles();

  const wiredIp = useMemo(() => getWiredIp(hubInfo), [hubInfo]);
  const wirelessIp = useMemo(() => getWirelessIp(hubInfo), [hubInfo]);
  const mainIp = wiredIp || wirelessIp || "";

  return (
    <Box className={classes.root}>
      <Stack spacing={20}>
        <p className={classes.description}>Alfredo trovato!<br />Di seguito l’indirizzo IP per accedervi.</p>
        {(wiredIp && wirelessIp) ? (
          <Stack spacing={0}>
            <IpAddressLine ipAddress={wiredIp} type={IpAddressLineType.Wired} />
            <IpAddressLine ipAddress={wirelessIp} type={IpAddressLineType.Wireless} />
          </Stack>
        ) : (
          <IpAddressLine ipAddress={mainIp} type={IpAddressLineType.Main} />
        )}
      </Stack>
    </Box>
  );
}
const useStyles = createStyles(theme => ({
  root: {
    color: theme.black,
    backgroundColor: theme.white,
    lineHeight: "28px",
    fontSize: 16,
    borderRadius: 6,
    padding: 18,
    filter: "drop-shadow(8px 4px 20px rgba(255, 255, 255, 0.2))"
  },

  description: {
    margin: 0
  }
}));
