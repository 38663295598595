import React from 'react';
import { Button as MButton, ButtonProps, createStyles } from '@mantine/core';

type Props<T = 'button'> = ButtonProps<T>;

export function Button(props: Props) {
  const { classes } = useStyles();

  return (
    <MButton
      classNames={{
        label: classes.label,
      }}
      {...props}
    />
  );
}

const useStyles = createStyles(() => ({
  label: {
    overflow: 'unset',
    whiteSpace: 'unset',
  },
}));
