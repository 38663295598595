import { Box, createStyles } from "@mantine/core";
import React from "react";

interface Props {
  error: number | null;
  last_seen_at: number | null;
}

const localizeError = (errorCode: number | null, last_seen_at: number | null, boldClass: string): React.ReactNode => {
  switch (errorCode) {
    case null:
      if (last_seen_at === null || isNaN(Number(last_seen_at))) {
        return `Questo Alfredo non ha la connessione attiva.`;
      } else {
        const dateRaw = new Date(last_seen_at * 1000);
        const date = `${dateRaw.getDate().toString().padStart(2, '0')}/${(dateRaw.getMonth() + 1).toString().padStart(2, '0')}/${dateRaw.getFullYear()}`;
        const time = `${dateRaw.getHours().toString().padStart(2, '0')}:${dateRaw.getMinutes().toString().padStart(2, '0')}`;
        return <>Questo Alfredo non ha la connessione attiva. L’ultima volta è stato rilevato il <span className={boldClass}>{date} alle ore {time}</span>.</>;
      }
    case 422:
      return 'Questo Alfredo è stato già collegato all’account sul cloud.';
    case 404:
    default:
      return 'Questo Alfredo non è ancora stato connesso al cloud.';
  }
}

export function ErrorBox({ error, last_seen_at }: Props) {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <p className={classes.description}>
        {localizeError(error, last_seen_at, classes.boldy)}
      </p>
    </Box>
  );
}
const useStyles = createStyles((theme) => ({
  root: {
    color: theme.white,
    backgroundColor: "transparent",
    lineHeight: "28px",
    fontSize: 16,
    borderRadius: 6,
    padding: 18,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.white
  },

  description: {
    margin: 0,
  },

  boldy: {
    fontWeight: 500
  }
}));
